<template>
  <div style="padding:16px">

    <!-- 头部部分 -->
    <div ref="search_d">
      <el-form label-width="70px" >
        <el-row>
          <el-col :span="6">
 <el-form-item label="公司名称">
          <el-input clearable v-model="queryInfo.condition.insureName"></el-input>
        </el-form-item>
          </el-col>
          <el-col :span="6">
<el-form-item label="类型">
          <el-select clearable v-model="queryInfo.condition.type">
             <el-option label="保险服务" value="1"></el-option>
              <el-option label="税筹及工商咨询" value="2"></el-option>
              <el-option label="法律咨询" value="3"></el-option>
              <el-option label="资质咨询" value="4"></el-option>
          </el-select>
        </el-form-item>
          </el-col>
          <el-col :span="6">
 <el-form-item label="手机号">
          <el-input clearable v-model="queryInfo.condition.phone"></el-input>
        </el-form-item>
          </el-col>
          <el-col :span="6" class="ty2">
            <el-button style="margin-left:10px" :loading="tableLoading" type="primary" @click="getList">查询</el-button>
            <el-button icon="el-icon-plus"  @click="newBtn">新建</el-button>
          </el-col>
        </el-row>
      </el-form>

    </div>

    <!-- 表格部分 -->
    <div>
      <el-table v-loading="tableLoading" border ref="table" :data="tableData"
      tooltip-effect="dark" style="width: 100%" height="calc(100vh - 220px)">
        <el-table-column label="序号" type="index" width="50" fixed="left" align="center"></el-table-column>
          <el-table-column label="公司名称" fixed="left" align="center" min-width="120px">
            <template slot-scope="scope">{{ scope.row.insureName }}</template>
          </el-table-column>
               <el-table-column label="类型" align="center" >
            <template slot-scope="{row}">
              <span v-if="row.type==1">保险服务</span>
              <span v-if="row.type==2">税筹及工商咨询</span>
              <span v-if="row.type==3">法律咨询</span>
              <span v-if="row.type==4">资质咨询</span>
            </template>
          </el-table-column>
          <el-table-column label="公司logo" min-width="100px" align="center" >
            <template slot-scope="scope">
              <el-image style="height:60px;width:60px" :preview-src-list="[scope.row.insureLogo]"
              :src="scope.row.insureLogo"></el-image>
            </template>
          </el-table-column>
          <el-table-column label="轮播图"  min-width="100px" align="center" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-image style="height:60px;width:60px" v-if="scope.row.bannerUrl"
               :preview-src-list="scope.row.bannerUrl.split(',')"
              :src="scope.row.bannerUrl.split(',')[0]"></el-image>
            </template>
          </el-table-column>
          <el-table-column label="公司简介" min-width="300px" align="center" >
            <template slot-scope="scope">{{ scope.row.insureInfo }}</template>
          </el-table-column>
          <el-table-column label="图片或视频介绍" min-width="100px" align="center" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-image v-if="scope.row.fileUrl" style="height:60px;width:60px" :preview-src-list="[scope.row.fileUrl]"
              :src="scope.row.fileUrl"></el-image></template>
          </el-table-column>
          <el-table-column label="产品介绍" min-width="200px" align="center">
            <template slot-scope="scope">{{ scope.row.coverage }}</template>
          </el-table-column>
          <el-table-column label="电话" align="center" min-width="100px">
            <template slot-scope="scope">{{ scope.row.phone }}</template>
          </el-table-column>
          <el-table-column label="微信个人二维码" min-width="100px" align="center" >
            <template slot-scope="scope">
              <el-image v-if="scope.row.wechatQrCode" style="height:60px;width:60px" :preview-src-list="[scope.row.wechatQrCode]"
              :src="scope.row.wechatQrCode"></el-image>
            </template>
          </el-table-column>

          <el-table-column label="创建时间" min-width="160px" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.createDt | dataFormat }}</template>
          </el-table-column>
        <el-table-column label="操作" width="135" fixed="right" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="edit(scope.row)" size="small">编辑</el-button>
            <el-popconfirm title="确定删除吗？" @confirm="handleDelte(scope.row.id)">
              <el-button slot="reference" type="text">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="block">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryInfo.currPage" :page-sizes="[100, 200, 300, 400]" :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>

    <el-dialog :title="(labelType=='add'?'新增':'编辑')" :visible.sync="showDialog" width="800px">
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-row  class="formDot">
          <el-col :span="12">
              <el-form-item label="公司名称" prop="siteName">
                <el-input v-model="form.insureName" placeholder="请输入公司名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="电话" prop="siteName">
                <el-input v-model="form.phone" placeholder="请输入电话"></el-input>
              </el-form-item>
            </el-col>

         <el-col :span="12">
          <el-form-item label="类型" required>
            <el-select v-model="form.type">
              <el-option label="保险服务" value="1"></el-option>
              <el-option label="税筹及工商咨询" value="2"></el-option>
              <el-option label="法律咨询" value="3"></el-option>
              <el-option label="资质咨询" value="4"></el-option>
            </el-select>
          </el-form-item>
         </el-col>
            <el-col :span="12">
              <el-form-item label="排序" prop="siteName">
              <el-input-number size="mini" controls-position="right" :precision="0" :min="1" v-model.number="form.seq" placeholder="请输入排序">

              </el-input-number>
              </el-form-item>
            </el-col>


            <el-col :span="12">
              <el-form-item label="公司logo" prop="siteName">
                <el-upload
  class="avatar-uploader"
  :action="$uploadURL"
  :show-file-list="false"
  :on-success="handleLogoSuccess"
  :before-upload="beforeAvatarUpload">
  <img v-if="form.insureLogo" :src="form.insureLogo" class="avatar">
  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
</el-upload>

      </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="微信二维码" prop="siteName">
                <el-upload
  class="avatar-uploader"
  :action="$uploadURL"
  :show-file-list="false"
  :on-success="handleWechatSuccess"
  :before-upload="beforeAvatarUpload">
  <img v-if="form.wechatQrCode" :src="form.wechatQrCode" class="avatar">
  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
</el-upload>
       <!-- <el-input v-model="form.wechatQrCode" placeholder="请输入名称"></el-input> -->
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="图片/视频介绍" prop="siteName">
                <el-upload
  class="avatar-uploader"
  :action="$uploadURL"
  :show-file-list="false"
  :on-success="handleFileUrlSuccess"
  :before-upload="beforeUpload">
  <img v-if="fileType(form.fileUrl)=='img'" :src="form.fileUrl" class="avatar">
  <video v-else-if="fileType(form.fileUrl)=='mp4'" controls class="el-icon-plus avatar-uploader-icon"></video>
  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
</el-upload>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="轮播图" class="bannerItem" >
                <el-upload
                :on-preview="handlePreview"
                multiple
                :on-success="handleBannerUrlSuccess"
                :action="$uploadURL"
                :file-list="fileList"
  list-type="picture-card"
  :on-remove="handleBannerUrlRemove">
  <i class="el-icon-plus"></i>
</el-upload>
              </el-form-item>
            </el-col>
                        <el-col :span="12">
              <el-form-item label="产品介绍" >
                <el-input  type="textarea"  autosize
                v-model="form.coverage" placeholder="请输入产品介绍"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="公司简介" prop="siteName">
           <el-input type="textarea" autosize v-model="form.insureInfo" placeholder="请输入公司简介">

           </el-input>
              </el-form-item>
            </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button size="small" @click="showDialog = false">取消</el-button>
        <el-button size="small" type="primary" @click="handleSave">确定</el-button>
      </span>
    </el-dialog>
    <el-image-viewer  :on-close="closeViewer" v-if="showViewer"
     :url-list="viewList"></el-image-viewer>
  </div>
</template>

<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
export default {
  components: {
    ElImageViewer
  },
  data () {
    return {
      tableLoading:false,
      viewList:[],
      showViewer:false,
      fileList:[],
      labelType: 'add',
      rules: {},
      total: 0,
      queryInfo: {
        pageSize: 100,
        pageCount: 1,
        currPage: 1,
        condition: {
          type:"",
          insureName:"",
          phone:""

        }
      },
      form: {
        id:"",
        seq:1,
        insureName:"",
        insureLogo:"",
        insureInfo:"",
        wechatQrCode:"",
        coverage:"",
        phone:"",
        fileUrl:'',
        type:'1'
      },
      showDialog: false,
      tableData: [],
    };
  },
  created () {
    this.getList()
  },
  methods: {
    closeViewer(){
      this.showViewer=false
    },
    handlePreview(e){
      this.viewList=[e.url]
      this.showViewer=true
    },
    fileType(url){
      let index =url.lastIndexOf('.')
      let ext =url.slice(index+1)
      let isImg=['png','jpg','jpeg','svg','webp','gif']
      if(isImg.includes(ext)){
        return 'img'
      }
      if(ext=='mp4'){
        return 'mp4'
      }

    },
    handleBannerUrlSuccess(res){
      this.fileList.push({name:res.data.url,url:res.data.url})
    },
    handleFileUrlSuccess(res){
      this.form.fileUrl = res.data.url
    },
    handleBannerUrlRemove(res){
      let index=this.fileList.findIndex(item=>{
        return item.url==res.url
      })
     if(index!=-1){
      this.fileList.splice(index,1)
     }
    },
    handleLogoSuccess(res, file) {
        this.form.insureLogo = res.data.url
      },
      handleWechatSuccess(res){
        this.form.wechatQrCode = res.data.url
      },
      beforeAvatarUpload(file) {
        const isImg = file.type.slice(0,5) === 'image';
        if (!isImg) {
          this.$message.error('请上传图片格式的文件');
        }
        return isImg;
      },
      beforeUpload(file){
        const isImg = file.type.slice(0,5) === 'image';
        const isVideo = file.type.slice(0,5) === 'video';
        if (!isImg && !isVideo) {
          this.$message.error('请上传图片或视频文件');
        }
        return isImg || isVideo;
      },
    //获取数据
    getList () {
	  var that = this;
    this.tableLoading=true
	  that.$http.post("/insureCompany/list", that.queryInfo).then(function (response) {
      that.tableLoading=false
        if (response.data.code == 200) {
          that.total = response.data.data.count;
          that.queryInfo.currPage=response.data.data.currPage
          that.tableData = response.data.data.data;
        }
      });
    },

    // 保存
   async handleSave () {
    let data=JSON.parse(JSON.stringify(this.form))
    if(!data.type){
      this.$message.info('请选择类型')
      return
    }
    let bannerList=[]
    this.fileList.forEach(item=>{
      bannerList.push(item.url)
    })
    data.bannerUrl=bannerList.join(',')
    if(this.labelType=='add'){
      delete data.id
    }
    let res=await this.$http.post('/insureCompany/save',data)
		  if (res.data.code == 200) {
			  this.$notify.success({
				title: "提示",
				message: "保存成功",
				showClose: true,
			  });
			 this.showDialog = false
			  this.getList()
		  }else{
			  this.$notify.info({
				title: "提示",
				message: res.data.message,
				showClose: true,
			  });
		  }

    },

    //修改
    edit (row) {
      this.showDialog = true;
      this.labelType = 'edit'
      let { id, seq,insureName,insureInfo,insureLogo,wechatQrCode,coverage,phone,fileUrl,bannerUrl,type}=row
      this.form.id = id
      this.form.seq = seq
      this.form.insureName = insureName
      this.form.insureLogo = insureLogo
      this.form.wechatQrCode = wechatQrCode
      this.form.coverage = coverage
      this.form.phone = phone
      this.form.fileUrl = fileUrl
      this.form.insureInfo=insureInfo
      this.form.type=type
      let arr=bannerUrl.split(',')
      if(bannerUrl.trim()==''){
        arr=[]
      }
      let list=arr.map(item=>{return {name:item,url:item}})
      this.fileList=list
    },
	// 删除用户
    async handleDelte(id) {
      let res=await this.$http.post("/insureCompany/delete" ,{id})
			if(res.data.code == 200){
			 this.$notify.success({
				title: "提示",
				message: "删除成功",
				showClose: true,
			  });
			  this.getList();
			}else{
				 this.$notify.info({
					title: "提示",
					message: res.data.message,
					showClose: true,
				  });
			}
    },

    //新增按钮
    newBtn () {
      this.labelType = 'add'
      this.showDialog = true;
      this.fileList=[]
      this.form =   {
        id:"",
        seq:1,
        insureName:"",
        insureLogo:"",
        wechatQrCode:"",
        coverage:"",
        phone:"",
        fileUrl:'',
        type:'1'
      }
    },

    // 修改页数大小
    handleSizeChange (val) {
      this.queryInfo.pageSize = val;
      this.getList()
    },

    // 获取当前页面
    handleCurrentChange (val) {
      this.queryInfo.currPage = val;
      this.getList()
    },

  },
};
</script>

<style lang="scss" scoped>
 /deep/.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  /deep/.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 68px;
    height:68px;
    line-height: 68px;
    text-align: center;
  }
  .avatar {
    width: 68px;
    height: 68px;
    display: block;
  }
  /deep/ .el-upload.el-upload--picture-card{
    width: 68px;
    height: 68px;
    display: block;
    line-height:68px;
    i{
      line-height: 68px;
    }
  }
  /deep/ li.el-upload-list__item.is-success{
    height: 68px;
    width: 68px;
  }
  /deep/ .formDot{
    display: flex;
    flex-wrap: wrap;
    .el-form-item__label::after{
      content: ':';
      padding-left: 2px;
    }
  }
  /deep/ .el-upload-list__item-actions{
    >span{
      font-size: 17px !important;
      margin-left: 0 !important;
      padding: 2px;
    }
  }
  /deep/ .el-image-viewer__wrapper{
    z-index: 202499 !important;
  }
  /deep/ .bannerItem{
    .el-form-item__content{
      >div{
        display: flex;
        flex-wrap: wrap;
      }
    }
  }
  /deep/.el-table__body-wrapper::-webkit-scrollbar-thumb{
 background:#409EFF;
 cursor: pointer;
}
/deep/.el-table__fixed{
  height: calc(100% - 8px) !important;
}
/deep/.el-table__fixed-right{
  height: calc(100% - 8px) !important;
}
/deep/ .el-upload-list__item {
  transition: none !important;
}

/deep/.el-table{
  margin-top: 0;
}
/deep/ .ty2{
  transform:translateY(2px);
}
</style>

